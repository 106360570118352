<template>
  <v-container
    id="user-management"
    fluid
    tag="section"
  >
    <v-row>
      <v-col class="12">
        <v-tabs
          v-model="tab"
          fixed-tabs
        >
          <v-tab :disabled="isLoading">
            <span class="tab-header">User Info</span>
          </v-tab>
          <v-tab :disabled="isLoading || isNewUser">
            <span class="tab-header">Organization Structure</span>
          </v-tab>
          <v-tab :disabled="isLoading || isNewUser">
            <span class="tab-header">Eze Settings</span>
          </v-tab>
          <v-tab :disabled="isLoading || isNewUser">
            <span class="tab-header">Other Settings</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-model="tab"
          class="pb-1"
        >
          <v-tab-item>
            <v-form
              ref="form"
              class="ma-5"
            >
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.firstName"
                    :disabled="isLoading"
                    :rules="[rules.required, rules.min1]"
                    filled
                    label="First Name"
                    counter="64"
                    maxlength="64"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.lastName"
                    :disabled="isLoading"
                    :rules="[rules.required, rules.min1]"
                    filled
                    label="Last Name"
                    counter="64"
                    maxlength="64"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.username"
                    :disabled="isLoading || !isNewUser"
                    :rules="[rules.required, rules.min5]"
                    filled
                    label="Username"
                    counter="64"
                    maxlength="64"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.email"
                    :disabled="isLoading"
                    :rules="[rules.required, rules.min5]"
                    filled
                    label="Email"
                    counter="64"
                    maxlength="64"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.password"
                    :disabled="isLoading"
                    :rules="!!user.password || !!user.passwordRepeated || isNewUser ? [rules.required, rules.min5] : []"
                    filled
                    label="Password"
                    type="password"
                    counter="64"
                    maxlength="64"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.passwordRepeated"
                    :disabled="isLoading"
                    :rules="!!user.password || !!user.passwordRepeated || isNewUser ? [rules.required, rules.min5] : []"
                    filled
                    label="Repeat Password"
                    counter="64"
                    maxlength="64"
                    type="password"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.sterlingReportId"
                    :disabled="isLoading"
                    filled
                    label="Sterling report ID"
                    counter="60"
                    maxlength="60"
                    clearable
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="user.ezeReportTradeId"
                    :disabled="isLoading"
                    filled
                    label="Eze report ID"
                    counter="24"
                    maxlength="24"
                    clearable
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="user.permissions"
                    :items="permissions"
                    label="Choose permissions"
                    item-value="value"
                    :disabled="isLoading"
                    item-text="label"
                    filled
                    multiple
                    chips
                    persistent-hint
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="createdAt"
                    :disabled="true"
                    filled
                    no-resize
                    full-width
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="updatedAt"
                    :disabled="true"
                    filled
                    no-resize
                    full-width
                  />
                </v-col>
                <v-col cols="2">
                  <v-switch
                    v-model="user.locked"
                    :disabled="isLoading"
                    :color="user.locked ? 'red' : 'green'"
                    inset
                    :label="`${user.locked ? 'Locked' : 'Unlocked'}`"
                  />
                </v-col>
                <v-col
                  cols="2"
                  class="text-right"
                >
                  <v-btn
                    :disabled="isLoading"
                    color="primary"
                    class="white--text btn-center"
                    @click="saveUser()"
                  >
                    {{ isNewUser ? 'Save' : 'Edit' }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="selectedDirectManagerAccessId"
                      class="ml-10 mr-10"
                      :disabled="isLoading"
                      :items="availableDirectManager"
                      color="primary"
                      chips
                      auto-select-first
                      label="Direct Manager"
                      item-text="name"
                      item-value="accessId"
                    />
                  </v-col>
                  <v-col cols="12">
                    <div class="text-right ma-10">
                      <v-btn
                        color="primary"
                        :disabled="isLoading"
                        class="white--text btn-center"
                        right
                        target="_blank"
                        @click="saveDirectManager"
                      >
                        Save
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="selectedManageByMe"
                      class="ml-10 mr-10"
                      :disabled="isNewUser || isLoading || !userHasPermissions('BLOTTER_MANAGER')"
                      :items="availableEmployees"
                      color="primary"
                      chips
                      multiple
                      auto-select-first
                      label="Manage by me"
                      item-text="name"
                      item-value="accessId"
                    />
                  </v-col>
                  <v-col cols="12">
                    <div class="text-right ma-10">
                      <v-btn
                        color="primary"
                        :disabled="isNewUser || isLoading || !userHasPermissions('BLOTTER_MANAGER')"
                        class="white--text btn-center"
                        right
                        target="_blank"
                        @click="saveManageByMe"
                      >
                        Save
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-form
              ref="userFeeForm"
              class="ma-5"
            >
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    v-model="userFee.commission"
                    :disabled="isLoading"
                    :rules="[rules.required, rules.min1]"
                    filled
                    label="Fee Commission"
                    counter="8"
                    step="0.01"
                    type="number"
                    maxlength="8"
                    clearable
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="userFee.regFee"
                    :disabled="isLoading"
                    :rules="[rules.required, rules.min1]"
                    filled
                    label="Reg Fee"
                    counter="8"
                    maxlength="8"
                    step="0.01"
                    type="number"
                    clearable
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="userFee.smiValue"
                    :disabled="isLoading"
                    :rules="[rules.required, rules.min1]"
                    filled
                    label="SMI Value (%)"
                    max="100"
                    min="0"
                    step="0.01"
                    counter="5"
                    type="number"
                    maxlength="5"
                    clearable
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="userFee.interestRate"
                    :disabled="isLoading"
                    :rules="[rules.required, rules.min1]"
                    filled
                    label="Interest Rate (%)"
                    max="100"
                    min="0"
                    step="0.01"
                    counter="5"
                    type="number"
                    maxlength="5"
                    lang="bg"
                    clearable
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="userFee.software"
                    :disabled="isLoading"
                    :rules="[rules.required, rules.min1]"
                    filled
                    label="Software"
                    min="0"
                    step="1"
                    counter="7"
                    type="number"
                    maxlength="7"
                    lang="bg"
                    clearable
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    :disabled="isLoading"
                    color="primary"
                    class="white--text btn-center"
                    @click="saveUserFee()"
                  >
                    {{ isNewUser ? 'Save' : 'Edit' }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab-item>
            <v-form
              ref="userFeeForm"
              class="ma-5"
            >
              <v-row>
                <v-col cols="4">
                  <v-switch
                    v-model="otherSettings.blotterDuty"
                    :disabled="isLoading"
                    :color="'red'"
                    inset
                    :label="`${otherSettings.blotterDuty ? 'Has blotter duty' : 'Not need to upload blotter'}`"
                    @change="changeBlotterDuty"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row>
      <div class="text-center">
        <v-dialog
          v-model="dialog.show"
          width="500"
        >
          <v-card>
            <v-card-title class="headline lighten-2">
              <span
                :class="`${dialog.error ? 'red--text' : 'blue--text'} text-uppercase`"
                style="letter-spacing: 0.3rem"
              > {{ dialog.title }} </span>
            </v-card-title>

            <v-card-text
              align="center"
            >
              {{ dialog.message }}
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="dialog.show = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import users from '@/shared/users'
import { mapGetters, mapState } from 'vuex'
import rules from '@/shared/rules'
import DateTimeService from '@/service/DateTimeService'

export default {
  name: 'UserManagement',
  data: () => ({
    tab: 0,
    isNewUser: false,
    isLoading: false,
    dialog: {
      show: false,
      title: '',
      message: ''
    },
    user: {
      id: null,
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      sterlingReportId: '',
      ezeReportTradeId: '',
      permissions: [],
      password: '',
      passwordRepeated: '',
      locked: false,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime()
    },
    userFee: {
      commission: 0,
      regFee: 0,
      smiValue: 0,
      interestRate: 0,
      software: 0
    },
    otherSettings: {
      blotterDuty: false
    },
    permissions: [
      { label: 'Dashboard', value: 'DASHBOARD' },
      { label: 'Sterling Reports', value: 'STERLING_REPORTS' },
      { label: 'Eze Reports', value: 'EZE_REPORTS' },
      { label: 'Support IT', value: 'SUPPORT_IT' },
      { label: 'Support Accounting', value: 'SUPPORT_ACCOUNTING' },
      { label: 'Support Management', value: 'SUPPORT_MANAGEMENT' },
      { label: 'Upload new blotter', value: 'BLOTTER_UPLOAD' },
      { label: 'Bonus Statement', value: 'MONTHLY_STATEMENT' },
      { label: 'Blotter Manger', value: 'BLOTTER_MANAGER' },
      { label: 'MNDB Tool Preview', value: 'MNDB_TOOL_TERMINAL' },
      { label: 'Library', value: 'LIBRARY_PREVIEW' },
      { label: 'Library Admin', value: 'LIBRARY_ADMIN' },
      { label: 'Dashboard Admin', value: 'DASHBOARD_ADMIN' },
      { label: 'Blotter Owner/Admin', value: 'BLOTTER_ADMIN' },
      { label: 'User Management', value: 'LIB_ACCESS_MODIFY' },
      { label: 'Sterling Reports Administration', value: 'STERLING_REPORTS_ADMIN' },
      { label: 'Eze Reports Administration', value: 'EZE_REPORTS_ADMIN' },
      { label: 'Monthly Statement Administration', value: 'MANAGE_MONTHLY_STATEMENT' },
      { label: 'Manage System Settings', value: 'MANAGE_SYSTEM_SETTINGS' },
      { label: 'View System Versions', value: 'SYSTEM_VERSIONS' },
      { label: 'Access Manual Positions', value: 'MANUAL_POSITION' }
    ],
    rules: rules,
    selectedDirectManagerAccessId: null,
    availableDirectManager: [],
    selectedManageByMe: null,
    availableEmployees: []
  }),
  getters: {
    ...mapState(['user'])
  },
  computed: {
    ...mapGetters(['hasRoles', 'hasPermission']),
    createdAt: function () {
      return `Created At: ${DateTimeService.format(new Date(this.user.createdAt))}`
    },
    updatedAt: function () {
      return `Last Update At: ${DateTimeService.format(new Date(this.user.updatedAt))}`
    }
  },
  beforeMount () {
    this.isNewUser = !!(this.$route.params.id && this.$route.params.id === '-1')

    if (!this.isNewUser) {
      this.fetchUser()
      this.fetchAvailableAccounts()
    }
  },
  methods: {
    userHasPermissions (permission) {
      return this.user.permissions.indexOf(permission) >= 0
    },
    async saveUserFee () {
      // if (this.$refs.userFeeForm.validate()) {
      this.isLoading = true
      const response = await users.saveUserFee(this.user.id, this.userFee)
      if (response.status === 200) {
        this.dialog.show = true
        this.dialog.title = 'Success'
        this.dialog.error = false
        this.dialog.message = `Successfully update fee for ${this.user.username} !`
      }
      this.isLoading = false
      // }
    },
    async saveUser () {
      if (this.$refs.form.validate()) {
        if (this.user.password !== this.user.passwordRepeated) {
          // password does not match
          this.dialog.show = true
          this.dialog.title = 'Error'
          this.dialog.error = true
          this.dialog.message = 'Entered passwords does not match !'
          return
        }
        if (this.isNewUser) {
          this.isLoading = true
          const response = await users.newUser({ ...this.user, username: this.user.username.toUpperCase() })
          if (response.status === 200) {
            this.user = response.data
            this.isNewUser = false
            this.user.password = ''
            this.user.passwordRepeated = ''
            this.user.permissions = this.user.permissions || []
            await this.$router.replace(`/users/${this.user.id}`)
            this.dialog.show = true
            this.dialog.title = 'Success'
            this.dialog.error = false
            this.dialog.message = `Successfully created user ${this.user.username} !`
          } else {
            this.dialog.show = true
            this.dialog.title = 'Error'
            this.dialog.error = true
            this.dialog.message = response.error.message
          }
          this.isLoading = false
        } else {
          this.isLoading = true
          const response = await users.editUser(this.user)
          if (response.status === 200) {
            this.user = response.data
            this.user.password = ''
            this.user.passwordRepeated = ''
            this.user.permissions = this.user.permissions || []
            this.dialog.show = true
            this.dialog.title = 'Success'
            this.dialog.error = false
            this.dialog.message = `Successfully edit user ${this.user.username} !`
          } else {
            this.dialog.show = true
            this.dialog.title = 'Error'
            this.dialog.error = true
            this.dialog.message = response.error.message
          }
          this.isLoading = false
        }
      }
    },
    async fetchAvailableAccounts () {
      this.isLoading = true
      let response = await users.listAvailableManager()
      if (response.status === 200) {
        this.availableDirectManager = response.data
      }
      response = await users.listAvailableEmployees()
      if (response.status === 200) {
        this.availableEmployees = response.data
      }
      this.isLoading = false
    },
    async fetchUser () {
      this.isLoading = true
      let response = await users.user(this.$route.params.id)
      if (response.status === 200) {
        this.user = response.data
        this.user.password = ''
        this.user.passwordRepeated = ''
      }
      response = await users.userOrganizationStructure(this.user.id)
      if (response.status === 200) {
        this.selectedDirectManagerAccessId = response.data.directManager
        this.selectedManageByMe = response.data.manageByMe
      }
      response = await users.fees(this.user.id)
      if (response.status === 200) {
        this.userFee = response.data
      }
      response = await users.fetchSettings(this.user.id)
      if (response.status === 200) {
        this.otherSettings.blotterDuty = response.data.blotterDuty
      }
      this.isLoading = false
    },
    async changeBlotterDuty (enabled) {
      this.isLoading = true
      await users.blotterDuty(this.user.id, enabled)
      this.isLoading = false
    },
    async saveDirectManager () {
      this.isLoading = true
      const response = await users.saveUserOrganizationStructure(this.user.id, { directManager: this.selectedDirectManagerAccessId, changeDirectManager: true })
      if (response.status === 200) {
        this.selectedDirectManagerAccessId = response.data.directManager
        this.selectedManageByMe = response.data.manageByMe
        this.dialog.show = true
        this.dialog.title = 'Success'
        this.dialog.error = false
        this.dialog.message = `Successfully save new Direct Manager to user ${this.user.username} !`
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = response.data.error.message
      }
      this.isLoading = false
    },
    async saveManageByMe () {
      this.isLoading = true
      const response = await users.saveUserOrganizationStructure(this.user.id, { manageByMe: this.selectedManageByMe, changeManageByMe: true })
      if (response.status === 200) {
        this.selectedDirectManagerAccessId = response.data.directManager
        this.selectedManageByMe = response.data.manageByMe
        this.dialog.show = true
        this.dialog.title = 'Success'
        this.dialog.error = false
        this.dialog.message = 'Successfully save Manager By me users'
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = response.data.error.message
      }
      this.isLoading = false
    }
  }
}
</script>
<style scoped>
.tab-header {
  font-weight: 400;
  letter-spacing: 0.3rem;
}
</style>
